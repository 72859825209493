
const TABLE_QUESTIONS = "Questions";
const TABLE_USERS = "Users";
const TABLE_ANSWERS = "Answers";
const TABLE_LEVELS = "Levels";
const TABLE_BATTLES = "Battles";
const TABLE_SCORES = "Scores";
const TABLE_PROMOS = "Promos";
const TABLE_GIFT_CODES = "GiftCodes";

const QUESTION_LIMIT_LEVEL = 5;
const QUESTION_LIMIT_SHUFFLE = 10;
const QUESTION_LIMIT_BATTLE = 10;
const POINTS_PER_ANSWER = 5;

// We only level up when user has all 5 questions correct.
// However, the questions (since v1.3.4) keep repeating until 
// user has everything correct, and are only sent in then.
// So, we use this flag to allow the user to include incorrect
// answers and STILL level up, as long as the number of correct
// answers is 5.
const ALLOW_LEVELUP_ALWAYS = true;

// Allow claiming a
const ALLOW_DOUBLE_CLAIM = true;

const POINTS_PER_ANSWER_BATTLE = 50;
const POINTS_PER_SECOND_BATTLE = -1;

const DEFAULT_LEADERBOARD_LENGTH = 10;

module.exports = {
    TABLE_QUESTIONS,
    TABLE_USERS,
    TABLE_ANSWERS,
    TABLE_LEVELS,
    TABLE_BATTLES,
    TABLE_SCORES,
    TABLE_PROMOS,
    TABLE_GIFT_CODES,

    QUESTION_LIMIT_LEVEL,
    QUESTION_LIMIT_SHUFFLE,
    QUESTION_LIMIT_BATTLE,

    POINTS_PER_ANSWER,
    POINTS_PER_ANSWER_BATTLE,
    POINTS_PER_SECOND_BATTLE,

    DEFAULT_LEADERBOARD_LENGTH,

    ALLOW_LEVELUP_ALWAYS,
    ALLOW_DOUBLE_CLAIM
}
