<template>
  <div class="container mt-3">
    <div class="row">
      <div class="col-lg-12">
        <h1><span>{{ l(this.params.lang, 'title_1') }}</span></h1>
        <p>
          {{ l(this.params.lang, 'description_1') }}
        </p>
        <p>
          <a href="https://apps.apple.com/us/app/compendium-medicine/id6473748682" target="_blank">
            <img src="@/assets/appstore-dark.svg" alt="Download on the App Store" height="85" class="store-badge" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=nl.compendiumgeneeskunde.app" target="_blank">
            <img src="@/assets/playstore.png" alt="Get it on Google Play" height="85" class="store-badge" />
          </a>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <h1><span>{{ l(this.params.lang, 'title_2') }}</span></h1>
        <p>
          {{ l(this.params.lang, 'description_2') }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <h1><span>{{ l(this.params.lang, 'title_3') }}</span></h1>
        <p>
          {{ l(this.params.lang, 'description_3') }}
        </p>

        <UserLoginComponent v-if="!isLoggedIn" @onLogin="onLogin" />

        <div v-if="error" class="alert alert-danger">{{ error }}</div>

        <div v-if="isLoggedIn">
          <p>
            <!-- <span v-if="user.displayName">Hi {{ user.displayName }}!</span>--> {{ l(this.params.lang,
          'logged_in_as') }}
            <span class="bold">{{
          user.email
        }}</span>.
          </p>

          <button @click="logout" class="comp-purple">{{ l(this.params.lang, 'sign_in_as_different_user') }}</button>
        </div>
      </div>
    </div>

    <div class="row" v-if="isLoggedIn && !error">
      <div class="col-lg-12">
        <h1><span>{{ l(this.params.lang, 'title_4') }}</span></h1>

        <RedeemPromoComponent v-if="!hasClaimed" @onClaim="onClaim" />
        <div v-else>
          <p>
            {{ l(this.params.lang, 'congrats') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserLoginComponent from './components/user-components/UserLoginComponent.vue';
import RedeemPromoComponent from './components/user-components/RedeemPromoComponent.vue'
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { fetchUser } from './repository/apiService.js';
import { l } from './repository/l.js';
import { ALLOW_DOUBLE_CLAIM } from '../api/src/db/constants.js';

export default {
  name: "app",
  components: {
    UserLoginComponent,
    RedeemPromoComponent
  },
  data() {
    return {
      user: null,
      isLoggedIn: false,
      hasClaimed: false,
      error: null
    }
  },
  computed: {
    params: function () {
      const NL = window.location.search.substring(1) == 'lang=nl';
      return {
        lang: NL ? 'nl' : 'en',
      };
    }
  },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // console.log(user);
        this.getUserFromBackend(user);
      } else {
        // User is signed out
        this.isLoggedIn = false;
        this.user = null;
        this.error = null;
      }
    });
  },
  methods: {
    l: l,
    async logout() {
      const auth = getAuth();
      await signOut(auth);
    },
    async onLogin(user) {
      //this.isLoggedIn = true;
      //this.user = user;
      this.error = null;
      this.getUserFromBackend(user);
    },
    async onClaim() {
      this.hasClaimed = true;
      this.error = null;
    },
    async getUserFromBackend(user) {
      try {
        const backendUser = await fetchUser();
        if (backendUser) {
          // User is signed in
          this.isLoggedIn = true;
          this.user = user;
          if (backendUser.giftClaimedAt && !ALLOW_DOUBLE_CLAIM) {
            this.hasClaimed = true;
            this.error = l(this.params.lang, 'already_claimed');
            return;
          } else {
            this.hasClaimed = false;
            this.error = null;
          }
        } else {
          this.error = l(this.params.lang, 'no_user_found');
          return;
        }
      } catch (err) {
        console.log(err);
        this.error = l(this.params.lang, 'no_user_found');
        return;
      }
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;800&display=swap');

body,
p,
label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

h1 {
  width: 100%;
  font-size: 26pt;
  margin-top: 1em;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

span.bold,
h1 span,
button {
  font-family: "Poppins", sans-serif !important;
  font-weight: 800 !important;
  font-style: normal !important;
}

p {
  font-size: 15pt;
  width: 100%;
}

.comp-yellow {
  background: var(--color-yellow);
}

.comp-red {
  background: var(--color-red);
}

.comp-purple {
  background: var(--color-purple);
}

.comp-blue {
  background: var(--color-blue);
}

.comp-green {
  background: var(--color-green);
}

.row {
  margin-top: 1rem;
}

.store-badge {
  height: 85px;
  width: auto;
  margin-right: 1em;
}

.card {
  background-color: #f5f5f5;
  border-radius: 1.5rem;
}

button {
  border-radius: 4rem !important;
  font-size: 15pt !important;
  padding: 0.5em 2.5em !important;
}

h1 span {
  background-color: #f7bd51;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding: .1em .3em;
  line-height: 1.625;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
</style>
